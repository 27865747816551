import Vue from "vue";
import Vuex from "vuex";
import {getToken, setToken, removeToken} from "@/util/cookie";
import {login} from "@/api/login";
import {getRole} from "@/api/admin-user";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: getToken(),
    role: ''
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_ROLE: (state, role) => {
      state.role = role;
    }
  },
  actions: {
    login({commit}, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          commit('SET_TOKEN', response);
          setToken(response);
          resolve(response);
        }).catch(error => reject(error));
      });
    },
    getRole({commit}) {
      return new Promise((resolve, reject) => {
        getRole().then(response => {
          commit('SET_ROLE', response);
          resolve(response);
        }).catch(error => reject(error));
      });
    },
    logout({commit}) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '');
        commit('SET_ROLE', '');
        removeToken();
        resolve();
      });
    }
  },
  getters: {
    token: state => state.token,
    role: state => state.role
  }
});
