<template>
  <div class="app-wrapper">
    <SideNav class="sidebar-nav" />
    <router-view class="app-main"></router-view>
  </div>
</template>

<script>
import SideNav from "./components/SideNav";

export default {
  name: "Layout",
  components: {
    SideNav
  }
}
</script>

<style lang="less">
.app-wrapper {
  height: 100%;
  display: flex;

  .side-nav{
    position: relative;
    height: 100%;
    flex-shrink: 0;
    flex: 0 0 40px;
  }

  .app-main {
    height: 100%;
    position: relative;
    flex: 1 1 auto;

    .main-container {
      position: absolute;
      top: 48px;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 20px;
      box-sizing: border-box;
      background-color: #F5F7FA;
    }
  }
}
</style>