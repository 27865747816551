import axios from 'axios';
import store from '../store';
import { getToken } from '../util/cookie';
import {Message, Spin} from "view-design";

const requestRaw = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 20000
});

requestRaw.interceptors.request.use(
  config => {
    Spin.show({
      render: (h) => {
          return h('div', [
              h('Icon', {
                  'class': 'spin-icon-load',
                  props: {
                      type: 'ios-loading',
                      size: 18
                  }
              }),
              h('div', 'Loading')
          ])
      }
    });
    if (store.getters.token) {
      config.headers['Authorization'] = "Bearer " + getToken();
    }
    return config;
  },
  error => {
    console.log(error);
    Spin.hide();
    return Promise.reject(error);
  }
);

requestRaw.interceptors.response.use(
  response => {
    Spin.hide();
    return response;
  },
  error => {
    console.log('err' + error);
    Spin.hide();
    Message.error(error.message);
    return Promise.reject(error);
  }
);

export { requestRaw };

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000
});

service.interceptors.request.use(
  config => {
    Spin.show({
      render: (h) => {
          return h('div', [
              h('Icon', {
                  'class': 'spin-icon-load',
                  props: {
                      type: 'ios-loading',
                      size: 18
                  }
              }),
              h('div', 'Loading')
          ])
      }
    });
    if (store.getters.token) {
      config.headers['Authorization'] = "Bearer " + getToken();
    }
    return config;
  },
  error => {
    console.log(error);
    Spin.hide();
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    Spin.hide();
    const res = response.data;
    if (res.status !== 200) {
        Message.error(res.message);
        if ((res.status === 401 || res.status === 403) && response.config.url !== "/login") {
            store.dispatch('logout').then(() => location.reload());
        }
        return Promise.reject(new Error(res.message || 'Error'));
    } else {
        return res.data;
    }
  },
  error => {
    console.log('err' + error);
    Spin.hide();
    Message.error(error.message);
    return Promise.reject(error);
  }
);

export default service;
