import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout/Layout";

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    component: () => import('@/views/Login'),
    meta: {
      title: "管理后台登录"
    }
  }, {
    path: "/",
    name: "home",
    redirect: "/client-info"
  }, {
    path: "/client-info",
    component: Layout,
    redirect: "/client-info/list",
    meta: {
      title: "租户管理",
      icon: "lock"
    },
    children: [
      {
        path: "list",
        component: () => import('@/views/client-info/Index'),
        name: "client-info-list",
        meta: {
          title: "租户管理"
        }
      }, {
        path: "add",
        component: () => import('@/views/client-info/Add'),
        name: "client-info-add",
        meta: {
          title: "新建租户"
        }
      }, {
        path: "edit/:id",
        component: () => import('@/views/client-info/Add'),
        name: "client-info-edit",
        meta: {
          title: "编辑租户"
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
